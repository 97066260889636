/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// .card-img {
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center center;
//     background-image: url("images/logo/hero__travelers-seniors.jpg")
// }

.mat-mdc-snack-bar-container.snackbar-success {
  --mdc-snackbar-container-color: #1bc943;
  --mat-mdc-snack-bar-button-color: #1bc943;
  --mdc-snackbar-supporting-text-color: white;
  // border: 2px solid red; 


}

/* Color of the close button */
.mat-mdc-snack-bar-container.snackbar-success .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: black;
  background: transparent;
  --mdc-snackbar-button-supporting-text-color: white;
}




.mat-mdc-snack-bar-container.snackbar-success .mat-mdc-button.mat-mdc-snack-bar-action {
  --mat-snack-bar-button-color: #1bc943;
  background: #1bc943;
  --mdc-snackbar-supporting-text-color: white;
  --mdc-snackbar-container-color: #1bc943;

}

.mat-mdc-snack-bar-container.snackbar-danger .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  background-color: #9e9c9a; 
  color: #dc2626; 
}
//snackbar css starts
.mat-mdc-snack-bar-container.snackbar-danger {
  --mdc-snackbar-container-color:#FFFFFF; 
  --mdc-snackbar-supporting-text-color: #dc2626; 
}

.mat-mdc-snack-bar-container.snackbar-success {
  --mdc-snackbar-container-color:#FFFFFF; 
  --mdc-snackbar-supporting-text-color: #438028; 
}
//snackbar css end
.mat-mdc-snack-bar-container.snackbar-warning .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  --mat-snack-bar-button-color: #96928f;
  background: #f88132;

}

.mat-mdc-snack-bar-container.snackbar-warning {
  --mdc-snackbar-container-color: #ee5b53;
  --mat-mdc-snack-bar-button-color: #ee5b53;
  --mdc-snackbar-supporting-text-color: white;

}
